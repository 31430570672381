import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {

  connect() {
    console.log("turbo stream controller connected")
    Turbo.renderStreamMessage(this.element.innerHTML);
  }

}