import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = ["count"]

  connect () {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: ".handle",
      // onUpdate: this.onUpdate,
      forceFallback: true,
      onChoose: function (event) {
        document.documentElement.classList.add("draggable-cursor");
      },
      onUnchoose: function (event) {
        document.documentElement.classList.remove("draggable-cursor");
      },
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

}