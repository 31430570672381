import { Controller } from "@hotwired/stimulus"

SHOW_LESS = "Show Less Filters"
SHOW_MORE = "Show More Filters"

export default class extends Controller {
  static targets = ["form", "filtersToggle", "filtersToggleButton", "additionalFilters", "activeFilters", "selectedIds", "unSelectedIds", "selectAll", "loadMore", "searchInput"]
  
  update(event) {
    if (this.hasSelectedIdsTarget) {
      this.deselectAll(); /* Updating the search deselects all the selected items */
    }
    this.refresh();
  }

  reorder() {
    this.refresh();    
  }

  refresh() {
    if (this.hasFormTarget) {
      event?.preventDefault();
      event?.stopPropagation();
      this.updateHeight();
      this.updateURL();
      this.formTarget.requestSubmit()
      // document.body.classList.add('loading');
      // setTimeout(() => this.updateHeight(), 100);
    }
  }

  debounceSubmit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
        // this.formTarget.requestSubmit()
        this.update();
      }, 250)
  }

  adjustWidth(event) {
    console.log("adjustWidth")
    this.searchInputTarget.style.width = 'auto'
    this.searchInputTarget.style.width = (this.searchInputTarget.scrollWidth + 10) + 'px'
  }

  connect() {
    document.body.classList.remove('loading');
    window.addEventListener('resize', this.updateHeight) 
    document.addEventListener('slim_select:after_change', this.updateHeight);
    // this.updateFilters()
    // this.updateHeight();
    // this.updateactiveFilters();

    document.addEventListener('search:select', this.select);
    document.addEventListener('search:unselect', this.unselect);
    document.addEventListener('search:refresh', this.refresh.bind(this));

    document.addEventListener('turbo:load', function() {
      const searchInput = document.getElementById('search-input');
      if (searchInput) {
        searchInput.focus();
        // Move cursor to the end of the input
        const length = searchInput.value.length;
        searchInput.setSelectionRange(length, length);
      }
    });
    setTimeout(() => this.adjustWidth(), 0);
  }

  disconnect() {
    window.removeEventListener('resize', this.updateHeight);

    document.removeEventListener('search:select', this.select);
    document.removeEventListener('search:unselect', this.unselect);
  }

  updateURL() {
    // Update the URL with the new query parameter
    const params = new URLSearchParams(location.search);
    // Loop over the formatTarget, and get all the inputs and set the URL Search Params
    Array.from(this.formTarget.elements).forEach((element) => {
      if (element.name) {
        if (element.type == 'checkbox' || element.type == 'radio') {
          if (element.checked) {
            params.set(element.name, element.value);
          }
        } else {
          if (element.value || ['q', 'selected_ids', 'unselected_ids', 'select_all', 'search_assigned_to', 'saved_search_id'].includes(element.name)) {
            params.set(element.name, element.value);
          }
        }
      }
    });

    window.history.pushState(history.state, "", `${location.pathname}?${params}`)
  }

  filtersToggle = (event) => {
    event.preventDefault();
    this.filtersToggleTarget.value = parseInt(this.filtersToggleTarget.value) == 0 ? 1 : 0;
    this.updateFilters();
    // this.updateactiveFilters();
    this.updateURL();
    this.updateActiveFilterToggleParameter();
  }

  updateActiveFilterToggleParameter = (toggleStatus) => {    
    var links = document.querySelectorAll('a[href*="filters_toggle="]');
    
    links.forEach((link) => {
      var href = link.getAttribute('href');      
      // Update the value of filters_toggle parameter based on toggleStatus
      var updatedHref = href.replace(/filters_toggle=([01])/, 'filters_toggle=' + this.filtersToggleTarget.value);
      link.setAttribute('href', updatedHref);
    });
  }

  updateFilters = () => {
    if (this.hasFiltersToggleButtonTarget) {
      this.additionalFiltersTarget.style.overflow = 'hidden';
      this.additionalFiltersTarget.style.transitionProperty = 'height';
      this.additionalFiltersTarget.style.transitionDuration = '300ms';
      
      
      if (parseInt(this.filtersToggleTarget.value) == 1) {
        this.additionalFiltersTarget.style.height = `${this.additionalFiltersTarget.scrollHeight}px`;
        this.filtersToggleButtonTarget.innerHTML = SHOW_LESS
        this.additionalFiltersTarget.classList.add("active")
        setTimeout(() => {
          // this.additionalFiltersTarget.style.height = `auto`;
          // this.additionalFiltersTarget.style.transitionDuration = '0ms';
        }, 300);
      } else {
        this.additionalFiltersTarget.style.height = `${this.additionalFiltersTarget.scrollHeight}px`; // we change from auto to fixed height to allow the transition
        this.filtersToggleButtonTarget.innerHTML = SHOW_MORE
        setTimeout(() => this.additionalFiltersTarget.style.height = `0px`, 0);
        setTimeout(() => this.additionalFiltersTarget.classList.remove("active"), 300);  
      }
    }
  }

  updateHeight = () => {
    if (this.hasFiltersToggleButtonTarget) {
      if (parseInt(this.filtersToggleTarget.value) == 1) {
        this.additionalFiltersTarget.style.height = 'auto';
        setTimeout(() => {
          this.additionalFiltersTarget.style.height = `${this.additionalFiltersTarget.scrollHeight}px`;
        }, 0);
        // this.additionalFiltersTarget.style.height = `${this.additionalFiltersTarget.scrollHeight}px`;
      }
    }
  }

  // updateactiveFilters = () => {
  //   if (this.hasFiltersToggleButtonTarget) {
  //     this.activeFiltersTarget.style.overflow = 'hidden';
  //     this.activeFiltersTarget.style.transitionProperty = 'height';
  //     this.activeFiltersTarget.style.transitionDuration = '300ms';
      
  //     if (parseInt(this.filtersToggleTarget.value) == 1) {
  //       this.activeFiltersTarget.style.height = `${this.activeFiltersTarget.scrollHeight}px`; // we change from auto to fixed height to allow the transition
  //       setTimeout(() => this.activeFiltersTarget.style.height = `0px`, 0);

  //     } else {
  //       this.activeFiltersTarget.style.height = `${this.activeFiltersTarget.scrollHeight}px`;
  //       setTimeout(() => {
  //         // this.activeFiltersTarget.style.height = `auto`;
  //         // this.activeFiltersTarget.style.transitionDuration = '0ms';
  //       }, 300);

  //     }
  //   }
  // }


  // Normally we keep track of the selected IDs, 
  // However if select all has been selected we keep track of the unselected ID's
  // So that if a result set has 1000s of ID's we don't need to keep track of all the selected ID's
  select = (event) => {
    if (this.selectAllTarget.value == '1') {
      let ids = this.unSelectedIdsTarget.value.split(',').filter(id => id != '')
    
      if (event.target.checked) {      
        ids = ids.filter(id => id != event.target.value)
      } else {
        ids.push(event.target.value)
      }
      
      this.unSelectedIdsTarget.value = ids.join(',')
    } else {
      let ids = this.selectedIdsTarget.value.split(',').filter(id => id != '')
    
      if (event.target.checked) {      
        ids.push(event.target.value)
      } else {
        ids = ids.filter(id => id != event.target.value)
      }
      
      this.selectedIdsTarget.value = ids.join(',')
    }
    this.updateURL();
    this.updateSelectPopup();
  }

  deselectAll() {
    this.selectedIdsTarget.value = null;
    this.unSelectedIdsTarget.value = null;
    this.selectAllTarget.value = null;
    this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.updateURL();
    this.updateLoadMoreURL();
    this.updateSelectPopup();
  }

  selectAll = () => {
    this.selectedIdsTarget.value = null;
    this.unSelectedIdsTarget.value = null;
    this.selectAllTarget.value = '1';
    this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = true;
    });
    this.updateURL();
    this.updateLoadMoreURL();
    this.updateSelectPopup();
  }

  updateLoadMoreURL() {
    if (this.hasLoadMoreTarget) {
      const url = new URL(this.loadMoreTarget.href, window.location.origin);
      url.searchParams.set("select_all", this.selectAllTarget.value);
      this.loadMoreTarget.href = url.toString();
    }
  }

  // Do a turbo stream request to update the selected popup
  async updateSelectPopup() {
    const params = new URLSearchParams(location.search);
    const response = await fetch(`${location.origin}${location.pathname}/select_popup?${params}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      },
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

}
