import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["count"]

  connect () {
    this.updateCount = this.updateCount.bind(this)

    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', this.updateCount)
    })
  }

  disconnect () {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.removeEventListener('change', this.updateCount)
    })
  }

  updateCount () {
    if (this.countTarget) {
      this.countTarget.textContent = this.element.querySelectorAll('input:checked').length
    }
  }

}