import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "form", "input", "wordOptions", "submit"]

  connect() {
    console.log("connect", this.element)
    this.inputTarget.addEventListener('input', this.check.bind(this))
    this.formTarget.addEventListener('submit', this.submit.bind(this))
    
    this.check();
    this.focus()

    document.addEventListener('modal:show', () => this.enableInput.bind(this));
    document.addEventListener('modal:hide', () => this.disableInput.bind(this));
  }

  disconnect() {
    console.log("disconnect", this.element)
    document.removeEventListener('modal:show', this.enableInput);
    document.removeEventListener('modal:hide', this.disableInput);
  }

  disableInput() {
    this.inputTarget.disabled = true
  }

  enableInput() {
    this.inputTarget.disabled = false
  }

  check(event) {
    if (this.inputTarget.value.length == 0) {
      this.wordOptionsTarget.classList.remove("active")
      this.submitTarget.disabled = true
    } else {
      this.wordOptionsTarget.classList.add("active")
      this.submitTarget.disabled = false
    }
  }

  focus = () => {
    console.log("focus", this.element)
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length);
  }

  /* We want enter to submit the form, but we don't want the browser to jump to the next form
   * field, so we prevent the default action and blur the input field. and then submit the form. */
  submit(event) {
    document.activeElement.blur()
  }

}