import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    document.addEventListener("turbo:render", this.init.bind(this))
    this.init()
  }

  init() {
    if (this.flatpckr) {
      this.flatpckr.destroy();
    }
    this.flatpckr = flatpickr(this.inputTarget, {
      altInput: true,
      altFormat: "j F, Y",
      dateFormat: "Y-m-d",
      maxDate: "today",
    });
  }

  disconnect() {
    this.flatpckr.destroy();
  }

  clear() {
    this.flatpckr.clear();
  }

  open() {
    this.flatpckr.open();
  }

}
