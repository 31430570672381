import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "contextModal", "wrapper" ]
  
  toggle(event) {
    event.preventDefault();
    this.contextModalTarget.classList.toggle("active")
  }
  
  show(event) {
    event.preventDefault();
    event.stopPropagation();
    let trigger = event.target.closest(".actions--context-modal__trigger")

    this.contextModalTarget.classList.add("active")

    const rect = trigger.getBoundingClientRect()
    let left = rect.left + rect.width
    let top = rect.top + rect.height + 10

    this.wrapperTarget.style.left = `${left}px`;
    this.wrapperTarget.style.top = `${top}px`;
  }

  hide(event) {
    this.contextModalTarget.classList.remove("active")
  }  
}
